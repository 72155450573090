import React, { useEffect, useState } from "react";
import stars from "@/scss/images/stars.png";
import useWebSocket, { ReadyState } from "react-use-websocket";

type Stat = {
  title: string;
  value: string | number;
};
const Statistics: React.FC = () => {
  const [statsData, setStatsData] = useState<Record<string, number> | null>(
    null
  );
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    process.env.REACT_APP_WS_URL as string
  );

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      const data = {
        topic: "stats",
        action: "subscribe",
      };
      sendMessage(JSON.stringify(data));
    }
  }, [readyState, sendMessage]);

  useEffect(() => {
    if (lastMessage?.data) {
      try {
        const messageData = JSON.parse(lastMessage.data);
        if (messageData?.topic === "stats" && messageData?.type === "data") {
          setStatsData(messageData.data);
        }
      } catch (error) {
        console.error("Error parsing message data:", error);
      }
    }
  }, [lastMessage]);
  const formatNumber = (num: number): string => {
    return new Intl.NumberFormat().format(num);
  };

  const stats: Stat[] = statsData
    ? Object.entries(statsData)
        .filter(([key]) => key !== "timestamp")
        .map(([key, value]) => ({
          title: key.replace(/_/g, " ").toUpperCase(),
          value: typeof value === "number" ? formatNumber(value) : value,
        }))
    : [];

  return (
    <section className="text-center mt-[8rem] desktop:mt-[264px] relative">
      <img
        src={stars}
        alt="stars"
        className="absolute -top-[50%] h-[550px] -z-10"
      />
      <div className="container">
        <div data-aos="fade-up" data-aos-duration="800">
          <h3 className="text-label-md desktop:text-subheading-md text-blackshade-200 mb-3">
            Statistics
          </h3>
        </div>

        <div
          data-aos="fade-up"
          data-aos-duration="900"
          className="grid grid-cols-10 mt-10 desktop:mt-14 gap-8 desktop:gap-4 flex-wrap justify-center"
        >
          {stats.length > 0 ? (
            stats.map((item, idx) => (
              <div
                key={idx}
                className="gradient-border-box rounded-3xl p-4 relative col-span-10 mt-3 desktop:mt-0 desktop:col-span-2"
              >
                <div className="bg-golden-linear rounded-[20px] h-[148px] desktop:h-[160px] mb-4 flex items-center justify-center text-blackshade-900 text-display-md">
                  {item.value}
                </div>
                <div className="mx-auto text-white text-subheading-md">
                  {item.title}
                </div>
              </div>
            ))
          ) : (
            <p>Loading stats...</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Statistics;
